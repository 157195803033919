<template>
    <div class="input-date-picker">
        <p class="uk-text-left uk-margin-small-top uk-margin-remove-bottom uk-text-meta" v-if="label">
            {{ label }}
            <span v-if="info" :uk-tooltip="infoText" uk-icon="icon: info; ratio: 0.7" />
        </p>
        <div class="uk-margin-small">
            <FlatPickr
                v-if="showDatePicker"
                ref="picker"
                v-model="internalValue"
                no-label
                :type="type"
                :format="outputFormat"
                :formatted="formatted"
                :custom-shortcuts="shortcuts"
                color="#9040ff"
                :label="placeholder"
                :input-id="id"
                :range="range"
                :max-date="maxDate"
                :min-date="minDate"
                :error="invalid"
                :no-button-now="noButtonNow"
                @validate="emit"
                @input="change"
                :right="datePickerPosition"
                :dark="theme === 'dark'"
            />
        </div>
    </div>
</template>

<script>
import FlatPickr from '@platform/vue-ctk-date-time-picker';
import moment from 'moment';
import '@platform/vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { mapState } from 'vuex';

export const dateFormat = 'YYYY-MM-DD HH:mm';

export default {
    components: { FlatPickr },
    props: {
        value: { type: [ Object, String, Date ], default: null },
        start: { type: [ Object, String, Date ], default: null },
        end: { type: [ Object, String, Date ], default: null },
        label: { type: String, default: null },
        enableTime: { type: Boolean },
        time: { type: String, default: null },
        placeholder: { type: String, default: 'Select Date & Time' },
        id: { type: String, default: '' },
        withShortcuts: { type: Boolean },
        outputFormat: { type: String, default: 'YYYY-MM-DDTHH:mm:ss[Z]' },
        formatted: { type: String, default: 'llll' },
        maxDate: { type: String, default: null },
        minDate: { type: String, default: null },
        handleUtc: { type: Boolean },
        required: { type: Boolean },
        noButtonNow: { type: Boolean },
        range: { type: Boolean },
        datePickerPosition: { type: Boolean },
        infoText: { type: String, default: null },
        info: { type: Boolean }
    },
    data () {
        return {
            internalValue: this.initialValues(),
            shortcuts: ( this.withShortcuts ) ? [
                { key: 'beginningOfDay', label: this.$t( 'analytics.calendar.beginningOfDay' ), value: () => ({ start: moment().startOf( 'day' ), end: moment() }) },
                { key: '1DayAgo', label: this.$t( 'analytics.calendar.1DayAgo' ), value: 1 },
                { key: 'beginningOfWeek', label: this.$t( 'analytics.calendar.beginningOfWeek' ), value: () => ({ start: moment().startOf( 'week' ), end: moment() }) },
                { key: '1WeekAgo', label: this.$t( 'analytics.calendar.1WeekAgo' ), value: '-isoWeek' },
                { key: 'beginningOfMonth', label: this.$t( 'analytics.calendar.beginningOfMonth' ), value: () => ({ start: moment().startOf( 'month' ), end: moment() }) },
                { key: '1MonthAgo', label: this.$t( 'analytics.calendar.1MonthAgo' ), value: 30 },
                { key: '5MonthsAgo', label: this.$t( 'analytics.calendar.5MonthsAgo' ), value: () => ({ start: moment().subtract( 6, 'months' ).startOf( 'month' ), end: moment() }) },
                { key: 'beginningOfYear', label: this.$t( 'analytics.calendar.beginningOfYear' ), value: () => ({ start: moment().startOf( 'year' ), end: moment() }) },
                { key: '1YearAgo', label: this.$t( 'analytics.calendar.1YearAgo' ), value: '-year' }
            ] : [],
            triggerValidation: false,
            emitValue: null,
            shortcutEmitValue: { start: null, end: null },
            showDatePicker: true
        };
    },
    computed: {
        ...mapState({
            theme: state => state.venom.theme
        }),
        type () {
            return ( this.enableTime ) ? 'datetime' : 'date';
        },
        invalid () { return this.triggerValidation && this.required && this.internalValue === null; }
    },
    created () {
        if ( this.form )
            this.form.$register( this );
    },
    beforeDestroy () {
        if ( this.form )
            this.form.$deregister( this );
    },
    methods: {
        $triggerValidation () { this.triggerValidation = true; },
        emit ( ) {
            if ( this.internalValue !== null ) {
                const offset = new Date().getTimezoneOffset(); // get the local offset value
                if ( this.withShortcuts || this.range ) { // before sending values change back to utc values.
                    if ( this.internalValue.start ) {
                        this.shortcutEmitValue.start = ( this.handleUtc ) ? moment( this.internalValue?.start ).utcOffset( offset ).format( this.outputFormat )
                            : this.internalValue?.start;
                    }
                    if ( this.internalValue.end ) {
                        this.shortcutEmitValue.end = ( this.handleUtc ) ? moment( this.internalValue?.end ).utcOffset( offset ).format( this.outputFormat )
                            : this.internalValue?.end;
                    }
                    this.$emit( 'input', this.shortcutEmitValue );
                } else {
                    this.emitValue = ( this.handleUtc ) ? moment( this.internalValue ).utcOffset( offset ).format( this.outputFormat )
                        : this.internalValue;
                    this.$emit( 'input', this.emitValue );
                }
            }
        },
        change ( value ) {
            this.internalValue = value;
            if ( this.withShortcuts ) {
                const { start, end, shortcuts } = value;
                if ( ( start && end ) || shortcuts ) {
                    this.emit();
                }
            } else {
                this.emit();
            }

            if ( this.internalValue === null ) {
                this.$emit( 'input', {});
                this.$refs.picker.closePicker();
            }
        },
        initialValues ( ) {
            const offset = new Date().getTimezoneOffset();
            // The values are in utc from backend change into local time.
            if ( this.handleUtc ) {
                return ( this.withShortcuts || this.range ) ? {
                    start: ( this.start ) ? moment( this.start ).utcOffset( -( offset ) ).format( this.outputFormat ) : null,
                    end: ( this.end ) ? moment( this.end ).utcOffset( -( offset ) ).format( this.outputFormat ) : null
                }
                    : ( this.value ) ? moment( this.value ).utcOffset( -( offset ) ).format( this.outputFormat ) : null;
            }
            return ( this.withShortcuts ) ? { start: ( this.start ) ? this.start : null, end: ( this.end ) ? this.end : null }
                : ( this.value ) ? this.value : null;
        }
    },
    watch: {
        theme () {
            this.showDatePicker = false
            this.$nextTick(() => {
                this.showDatePicker = true
            })
        }
    }
};
</script>
